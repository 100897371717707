.container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100vw;
	height: 100vh;
	background-color: #f2f4f5;
}

.main {
	margin-top: 32px;
	width: 100%;
	height: 100%;
	flex-grow: 1;
	overflow: auto;
}

.footer {
	flex-shrink: 0;
	width: 100%;
	background-color: #f2f4f5;
}
