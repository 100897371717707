$primaryColor: var(--primary-color);

.title {
	display: flex;
	font-size: 24px;
	font-weight: 500;
	color: #3c494f;
	margin: 25px 0 44px 0;
}

.searchFieldContainer {
	padding-bottom: 40px;
	display: flex;
	gap: 16px;
}

.searchField {
	width: 500px;
}

.serialNumberCell {
	font-size: 14px;
}
.coldNeutralColor {
	color: #67808c;
}

.resetButton {
	color: $primaryColor !important;
	padding: 6px 16px !important;
	height: 39px;
}

/* link */
.serialNumberCell a:link,
.serialNumberCell a:visited,
.serialNumberCell a:hover,
.serialNumberCell a:active {
	color: var(--primary-color);
	text-decoration: none;
}
