$primaryColor: var(--primary-color);
$propertyNameColor: var(--08-cold-neutrals-300, #95a6ae);
$propertyValueColor: var(--08-cold-neutrals-500, #67808c);
$titleColor: var(--08-cold-neutrals-800, #3c494f);
$propertyValueDeviceId: var(--01-primary-500, #00baff);
$border: 1px solid var(--wireframe-greyscale-grey, #d9d9d9);

.content {
	margin-top: 32px;
	display: flow;
	gap: 10px;
	padding: 48px 48px 48px 48px;
}

.title,
.deviceId,
.propertyValueDeviceId {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.15px;
	text-align: left;
	color: $titleColor;
}

.deviceId {
	font-size: 24px;
	font-weight: 600;
}

.propertyValueDeviceId {
	padding-top: 4px;
	font-size: 16px;
	font-weight: 600;
	color: $propertyValueDeviceId;
}

.grid {
	border-bottom: $border;
	padding: 16px 0;
}

.gridItem {
	padding: 10px 0 !important;
}

.icon {
	width: 24px;
	height: 24px;
}

.propertyName,
.propertyValue,
.propertyValueStatus,
.propertyValueDescription {
	padding-top: 4px;
	font-size: 16px;
	text-align: left;
	line-height: 24px;
	color: $propertyValueColor;
}

.propertyName {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.25px;
	color: $propertyNameColor;
}

.propertyValue,
.propertyValueStatus {
	font-weight: 400;
	letter-spacing: 0.5px;
}

.propertyValueStatus {
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0;
	display: flex;
}

.propertyValueDescription {
	font-weight: 400;
	letter-spacing: 0.15px;
}
