body {
	margin: 0;
	font-family: 'Noto Sans', 'Noto Sans Mono', sans-serif;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--primary-color: #00aefe;
}
table td {
	max-width: 50vw;
	word-wrap: break-word
}
.download-btn {
	background: var(--08-cold-neutrals-500, #67808C) !important;
	border-radius: 4px !important;
}
.height-56 {
	height: 39px;
}
.sn-input {
	margin-bottom: 10px !important;
	margin-top: 10px !important;
	width: 100%;
}
.error-msg {
	font-size: small;
	color: red;
}